function addmarker(route, uluru, styles, locations, div) {
    (function($) {

        var map = '';
    
        var bounds = new google.maps.LatLngBounds();        

        // Re init map 
        map = new google.maps.Map(div, {
            center: uluru,
            zoom: 15,
            styles: styles
            // mapTypeId: google.maps.MapTypeId.ROADMAP
        });
      

        //marker loop
        var marker, i;
        for (i = 0; i < locations.length; i++) {  
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                // icon: icons[locations[i][3]].icon,
                icon : locations[i][3],
                map: map
            });

            bounds.extend(new google.maps.LatLng(locations[i][1], locations[i][2]));
            setInfoWindow(locations[i][0],locations[i][4],locations[i][5],locations[i][6]);
        }


        


        function setInfoWindow(title,link,image,target) {
            google.maps.event.addListener(marker, 'click', function(event) {

                if(target == null){
                    target = '';
                }

                var renderImage = '';
                var renderLink = '';
                var popupStyle = 'max-height: 245px';

                if(image != ''){
                    var renderImage = '<div class="imageContainer"><img src="'+image+'" /></div>';
                    var popupStyle = 'max-height: auto';
                }else{
                    // console.log(this);
                }

                if(link != ''){
                    var renderLink = '<a target="'+target+'" href="'+link+'" class="link underLineArrow primary">View</a>';
                }

                if(title != 'current'){

                    var contentString = '<div style="'+popupStyle+'" id="google-popup">'+
                    renderImage+
                    '<div class="content"><p>'+title+'</p>'+renderLink+'</div>'+
                    '</div>';


                    var iwindow = new google.maps.InfoWindow();

                    //close all info windows
                    jQuery(".gm-ui-hover-effect").click();
    
                    iwindow.setContent(contentString);
                    
                    iwindow.open(map, this);
    
                    google.maps.event.addListener(map, "click", function(event) {
                        iwindow.close();
                    });
                }
                
            });
        }
        


        // get json polygon
        if(route != ''){
            const json= require('/assets/json/'+route+'.json'); 

            const polygon = new google.maps.Polygon({
                paths: json,
                strokeColor: "#DE465C",
                strokeOpacity: 0.8,
                strokeWeight: 4,
                fillColor: "",
                fillOpacity: 0,
            });

            for(let i = 0; i < json.length; i++) {
                let obj = json[i];
                
                let lat = obj['lat'];
                let long = obj['lng'];
           
                bounds.extend(new google.maps.LatLng(lat, long));
                
            }
            
            polygon.setMap(map);

            
        }else{
            map.setCenter(uluru);
        }

        map.fitBounds(bounds, {top:50,bottom:50});
            

    })( jQuery );    
}

var styles = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#e9f1ef"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#a9c8bf"
            }
        ]
    },
    {
        "featureType": "landscape.natural.terrain",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#8dbeaf"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fbba00"
            }
        ]
    },
    {
        "featureType": "transit.station.bus",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "transit.station.rail",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#1d71b8"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#ffffff"
            }
        ]
    }
]


function initMap(uluru,styles,div) {
    (function($) {

        // The location of Uluru
        // The map, centered at Uluru
        const map = new google.maps.Map(div, {
            zoom: 15,
            center: uluru,
            styles: styles
        });
        

        window.initMap = initMap;
    })( jQuery );
}



function maps(){
    (function($) {
        
        window.initMap = initMap

    })( jQuery );
}

export { initMap, maps, addmarker, styles };